body {
  margin: 0;
  font-family: IRANSansWeb, code, Verdana, Geneva, Tahoma, sans-serif;
}
::-webkit-scrollbar {
  width: 0.5rem;
  z-index: 1;
  background-color: white;
}

::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey;
  //border: thin solid blue;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(185, 185, 185);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ff3a85;
}
* {
  box-sizing: border-box;
  font-family: "Verdana V2 V1", IRANSansWeb, Geneva, Tahoma, sans-serif;
}

@font-face {
  font-family: "IRANSansWeb";
  src: url("../fonts/A-Iranian-Sans/5c53f1a72f61b5b51e2ea79a22ebc38b.eot");
  src: url("../fonts/A-Iranian-Sans/5c53f1a72f61b5b51e2ea79a22ebc38b.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/A-Iranian-Sans/5c53f1a72f61b5b51e2ea79a22ebc38b.woff2")
      format("woff2"),
    url("../fonts/A-Iranian-Sans/5c53f1a72f61b5b51e2ea79a22ebc38b.woff")
      format("woff"),
    url("../fonts/A-Iranian-Sans/5c53f1a72f61b5b51e2ea79a22ebc38b.ttf")
      format("truetype"),
    url("../fonts/A-Iranian-Sans/5c53f1a72f61b5b51e2ea79a22ebc38b.svg#IRANSansWeb(FaNum) Medium")
      format("svg");
}
@font-face {
  font-family: "Verdana V2 V1";
  src: url("../fonts/verdana/1ae9b9868bd3121e932225b140a839a5.eot"); /* IE9*/
  src: url("../fonts/verdana/1ae9b9868bd3121e932225b140a839a5.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/verdana/1ae9b9868bd3121e932225b140a839a5.woff2")
      format("woff2"),
    /* chrome、firefox */
      url("../fonts/verdana/1ae9b9868bd3121e932225b140a839a5.woff")
      format("woff"),
    /* chrome、firefox */
      url("../fonts/verdana/1ae9b9868bd3121e932225b140a839a5.ttf")
      format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url("../fonts/verdana/1ae9b9868bd3121e932225b140a839a5.svg#Verdana V2 V1")
      format("svg"); /* iOS 4.1- */
}
